// dependencies.
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// utils.
import AnimatedNumber from 'src/js/utils/animate-numbers'
import fetchAssetsCount from 'src/js/utils/fetchAssetsCount'
import fetchPageData from 'js/utils/fetchPageData'

// Styles & Images:
import 'src/components/heading/scss/AssetCountHeading.scss'
import useFetchAssets from '../../js/assets/hooks/useFetchAssets'

// Partials:
const AssetsCountCopy = ({ page, src, className, assetsCount }) => {
  /* Fetch data depending on the props set */
  const data = fetchPageData(page, src, 'h2', 'assets')
  const replacedData = data.replace(/000/g, `${assetsCount.toLocaleString('en-US')}+`)

  return (
    data && (
      <h2 className={className}>
        <span dangerouslySetInnerHTML={{ __html: replacedData }} />
      </h2>
    )
  )
}

// Main component:
const AssetCountHeading = ({ id, page, src, wallet, light, withCommas, replaceCount = false }) => {
  const { count } = useFetchAssets()
  const assetsCount = replaceCount || fetchAssetsCount({ count, platform: wallet })

  return (
    <div
      id={id}
      className={classNames('x__assets-count-heading', {
        'x__assets-count-heading--light': light,
      })}
    >
      <span className="x__animated-number">
        <AnimatedNumber value={assetsCount} withCommas={withCommas} />+
      </span>

      <AssetsCountCopy page={page} src={src} className="x__heading" assetsCount={assetsCount} />
    </div>
  )
}

// Main component props:
AssetCountHeading.propTypes = {
  id: PropTypes.string,
  page: PropTypes.string,
  src: PropTypes.string,
  wallet: PropTypes.oneOf(['mobile', 'desktop', 'trezor', null]),
  light: PropTypes.bool,
}

AssetCountHeading.defaultProps = {
  id: 'asset-count-heading',
  page: 'default',
  src: 'Contents',
  wallet: null,
  light: false,
}

export default AssetCountHeading
